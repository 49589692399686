/* eslint-disable */
import { Options, Vue } from 'vue-class-component'
import { Action, Getter, Mutation, State } from 'vuex-class'
import { BDSM } from '@/store/bdsm/types';

const namespace: string = 'bdsm';
@Options<BdsmShowViewingPage>({
	// watch: {
	// 	setPages () {
	// 		let numberOfPages = Math.ceil(this.getBDSMVideo.length / this.perPage);
	// 		for (let index = 1; index <= numberOfPages; index++) {
	// 			this.pages.push(index);
	// 		}
	// 	}
	// },
	computed: {
		pages() {
			this.allPages = Math.ceil(this.getBDSMVideo.length / this.videoPerPage);
			return this.allPages;
		},
		paginatedUsers() {
			let from = (this.pageNumber - 1) *  this.videoPerPage;
			let to = from + this.videoPerPage;
			return this.getBDSMVideo.slice(from, to);
		},
		nextPage() {
			if(this.pageNumber <= this.allPages) this.pageNumber++;
		},
		setHandlePage() {
			if(this.handlePage <= this.allPages) this.pageNumber = this.handlePage;
		}
	}
})

export default class BdsmShowViewingPage extends Vue {
	@State('bdsm') bdsm: BDSM | undefined;
	@Action('storeModelTodo', {namespace}) storeModelTodo: any;
	@Action('fetchBdsmModels', {namespace}) fetchBdsmModels: any;
	@Action('storeCameraPosition', {namespace}) storeCameraPosition: any;
	@Action('fetchBDSMVideo', {namespace}) fetchBDSMVideo: any;
	@Action('storeDonate', {namespace}) storeDonate: any;
	@Getter('getTimeLeft', {namespace}) getTimeLeft: any;
	@Getter('getBDSMVideo', {namespace}) getBDSMVideo: any;
	@Mutation('setTime', {namespace}) setTime: any;

	allPages: number = 0;
	videoPerPage: number = 9;
	pageNumber: number = 1;
	handlePage: number = 1;

	pageClick(page: number) {
		this.pageNumber = page;
	}

	toDonate(type: string) {
		switch(type) {
			case 'vip':
				this.storeDonate({
					vipDonate: true
				});
				break;
			case 'donate':
				this.storeDonate({
					donate: true
				});
				break;
			default:
				break;
		}
	}

	// paginate(pages: any) {
	// 	let page = this.page;
	// 	let perPage = this.perPage;
	// 	let from = (page * perPage) - perPage;
	// 	let to = (page * perPage);
	// 	pages = this.getBDSMVideo;
	// 	return  pages.slice(from, to);
	// }

	cameraButton(event: Event & {
		target: HTMLElement
	}) {
		const {target} = event;
		this.storeCameraPosition({
			cameraPosition: target.innerText
		});
	}

	onMainVideoFrame(type: string) {
		switch(type) {
		case 'peep':
			// this.storeModelViewingPeep({
			// 	peep: true
			// });
			break;
		case 'noLogin':
			this.$router.push({ name: 'login' }).then(r => r);
			break;
		case 'min':
			// this.storeModelViewingPerMinOrAll({
			// 	perMinutes: true
			// })
			break;
		case 'allShow':
			// this.storeModelViewingPerMinOrAll({
			// 	allShow: true
			// })
			break;
		default:
			break;
		}
	}

	onModelTodoButton(event: Event & {
		target: HTMLElement
	}) {
		const {target} = event;
		this.storeModelTodo({
			todoModel: target.innerText
		})
	}
	created() {
		this.fetchBdsmModels();
		this.fetchBDSMVideo();
	}
}
